export default {
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teade"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta e-mail"])},
  "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta parool"])},
  "et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti"])},
  "fusiomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusioomid"])},
  "locale_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
  "locale_et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eesti"])},
  "locale_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keel"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiil"])},
  "profile_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiili ülevaade"])},
  "searchbar_cancel-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista"])},
  "searchbar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsi.."])},
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi välja"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri"])},
  "statistics_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistika"])},
  "theme_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kohandatud"])},
  "theme_dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tume"])},
  "theme_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tavaline"])},
  "theme_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hele"])},
  "theme_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teema"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervitus"])}
}