<template>
  <div>
    <p>The Create Fusiome page</p>
  </div>
</template>

<script>
export default {
  name: "CreateFusiome"
}
</script>

<style scoped>

</style>