export default {
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter e-mail"])},
  "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
  "et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti"])},
  "fusiomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusiomes"])},
  "locale_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
  "locale_et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eesti"])},
  "locale_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "searchbar_cancel-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "searchbar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search.."])},
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "statistics_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "profile_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile page"])},
  "theme_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom"])},
  "theme_dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dark"])},
  "theme_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default"])},
  "theme_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["light"])},
  "theme_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])}
}